import UserService from "../services/user.service";

export const user = {
  namespaced: true,
  user: "",
  subscriptionExpiryDate: "",
  actions: {
    getUser({ commit }) {
      return UserService.getUser().then(
        response => {
          commit("setUser", response);
          return Promise.resolve(response);
        },
        error => {
          console.log(error.response);
          return Promise.reject(error);
        }
      );
    },
    getUsersSubscriptionExpiryDate({ commit }) {
      return UserService.getUsersSubscriptionExpiryDate().then(
        response => {
          commit("setUsersSubscriptionExpiryDate", response);
          return Promise.resolve(response);
        },
        error => {
          console.log(error.response);
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    setUser(state, res) {
      state.user = res;
    },
    setUsersSubscriptionExpiryDate(state, res) {
      state.subscriptionExpiryDate = res;
    }
  }
};
