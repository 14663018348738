<template>
  <form class="form shadow box" name="form" @submit.prevent="handleRegister">
    <label class="company-checkbox"
      ><input
        v-model="companyCheckbox"
        @change="clearFields()"
        type="checkbox"
      />
      Company</label
    >
    <div class="form-group">
      <label for="username">Login</label>
      <input
        id="username"
        v-model="user.username"
        v-validate="'max:50'"
        type="text"
        class="form-control"
        name="username"
      />
      <div v-if="submitted && errors.has('username')" class="alert-danger">
        The username can be up to 50 characters long.
      </div>
    </div>
    <div class="form-group">
      <label for="email">Email</label>
      <input
        id="email"
        v-model="user.email"
        v-validate="'required|email|max:50'"
        type="email"
        class="form-control"
        name="email"
      />
      <div v-if="submitted && errors.has('email')" class="alert-danger">
        Enter a valid email
      </div>
    </div>
    <div class="form-group">
      <label for="password">Password</label>
      <input
        id="password"
        v-model="user.password"
        v-validate="'required'"
        type="password"
        class="form-control"
        name="password"
      />
      <div v-if="submitted && errors.has('password')" class="alert-danger">
        Password is required
      </div>
    </div>
    <div v-show="!companyCheckbox" class="form-group">
      <label for="surname">Your Name</label>
      <input
        id="surname"
        v-model="user.surname"
        v-validate="'max:50'"
        type="text"
        class="form-control"
        name="surname"
      />
      <div v-if="submitted && errors.has('surname')" class="alert-danger">
        First name and last name can have a maximum of 50 characters
      </div>
    </div>
    <div v-show="companyCheckbox" class="form-group">
      <label for="companyName">Company Name</label>
      <input
        id="companyName"
        v-model="user.companyName"
        v-validate="'max:100'"
        type="text"
        class="form-control"
        name="companyName"
      />
      <div v-if="submitted && errors.has('companyName')" class="alert-danger">
        The company name can be up to 100 characters long
      </div>
    </div>
    <div v-show="companyCheckbox" class="form-group">
      <label for="nip"> Tax ID </label>
      <input
        id="nip"
        v-model="user.nip"
        v-validate="'length:10'"
        type="text"
        class="form-control"
        name="nip"
      />
      <div v-if="submitted && errors.has('nip')" class="alert-danger">
        The tax identification number must be 10 characters
      </div>
    </div>
    <div class="form-group">
      <label for="street">Street</label>
      <input
        id="street"
        v-model="user.street"
        v-validate="'max:80'"
        type="text"
        class="form-control"
        name="street"
      />
      <div v-if="submitted && errors.has('street')" class="alert-danger">
        The street can have a maximum of 80 characters
      </div>
    </div>
    <div class="form-group">
      <label for="city">City</label>
      <input
        id="city"
        v-model="user.city"
        v-validate="'max:80'"
        type="text"
        class="form-control"
        name="city"
      />
      <div v-if="submitted && errors.has('city')" class="alert-danger">
        The city can have a maximum of 80 characters
      </div>
    </div>
    <div class="form-group">
      <label for="postalCode">Postal code</label>
      <input
        id="postalCode"
        v-model="user.postalCode"
        v-validate="'min:5|max:10'"
        type="text"
        class="form-control"
        name="postalCode"
      />
      <div v-if="submitted && errors.has('postalCode')" class="alert-danger">
        The postcode can be between 5 and 10 characters long
      </div>
    </div>
    <a href="/regulamin"
      ><span class="text">* By registering you accept </span></a
    >
    <a href="/regulamin"><span class="text_bold">the regulations</span></a>
    <div class="text-right">
      <button class="mt-4 btn btn-yellow ">
        <span class="btn-yellow-inner" style="font-weight:bold;">REGISTER</span>
      </button>
    </div>

    <div
      v-if="message"
      class="alert"
      :class="successful ? 'alert-success' : 'alert-danger'"
    >
      {{ message.message }}
    </div>
  </form>
</template>

<script>
// import User from "../models/user";

export default {
  name: "Register",
  data() {
    return {
      user: {
        email: "",
        password: "",
        nip: "",
        companyName: "",
        username: "",
        surname: "",
        postalCode: "",
        city: "",
        street: "",
      },
      submitted: false,
      message: "",
      successful: false,
      companyCheckbox: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/payment");
    }
  },
  methods: {
    handleRegister() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.$store.dispatch("auth/register", this.user).then(
            (data) => {
              this.message = data.message;
              setTimeout(() => (this.message = ""), 5000);
              this.$router.push("success-register");
              this.successful = true;
            },
            (error) => {
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();

              if (this.message.message == "Error: Email is already in use!") {
                this.message.message = "Email is already in use!";
              }

              setTimeout(() => (this.message = false), 8000);
              this.successful = false;
            }
          );
        }
      });
    },
    clearFields() {
      const { user } = this;
      user.surname = "";
      user.nip = "";
      user.companyName = "";
    },
  },
};
</script>

<style scoped>
@import "../assets/css/main.css";
@import "./LoginRegister.module.css";
@media only screen and (max-width: 520px) {
  .box {
    width: 100%;
  }
}
.text {
  color: black;
  font-style: italic;
  margin-right: 4px;
}
.text_bold {
  color: black;
  font-style: italic;
  font-weight: bold;
  margin-right: 5px;
}
</style>
