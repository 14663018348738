<template>
  <div class="container-fluid">
    <div class="row no-gutters shadow" :style="blurStyle()">
      <div class="col-lg-6">
        <img src="../../src/assets/Mask Group 1.png" class="img-col" />
      </div>

      <div class="col-lg-6 text-col">
        <div class="text-left sectionTop">
          <h1 class="title text-center">
            How to prepare the <br />Cash Flow Statement?
          </h1>
          <h6 class="sectionTop title  text-center">
            <span class="strike-20">End of</span
            ><span class="strike-20">the year</span>
          </h6>
          <div style="margin-bottom:30px;" class=" text-center">
            <strong
              >Balance Sheets, Profit and Loss Accounts and Cash Flows?</strong
            >
          </div>
          <div class="sectionBottom text-center">
            I will help you to prepare them quickly, correctly, and efficiently!
          </div>
          <div class="sectionBottom  text-center">
            I present to you an application that helps to
            <span class="strike-20">effectively</span>
            <span class="strike-20">prepare</span>
            <span class="strike-20">a cash </span>
            <span class="strike-20">flow</span
            ><span class="strike-20 last-char">statement.</span>
          </div>
          <div class=" text-center">
            If you <span class="strike-20">work</span
            ><span class="strike-20">in</span
            ><span class="strike-20">accounting</span> and there are instances
            when <span class="strike-20">you don't </span
            ><span class="strike-20">know</span>
            <span class="strike-20">how</span
            ><span class="strike-20">to prepare</span
            ><span class="strike-20">a cash </span
            ><span class="strike-20">flow</span
            ><span class="strike-20">statement, </span>sometimes where at home,
            instead of relaxing after a long day at the office, you spend time
            on your laptop to get the job done?
          </div>
          <h6 class="sectionTop  text-center">
            <span class="strike-20">Well,</span
            ><span class="strike-20">this</span
            ><span class="strike-20">website</span
            ><span class="strike-20">is</span><span class="strike-20">for</span
            ><span class="strike-20 last-char">You!</span>
          </h6>
          <div class="wrap ">
            <router-link to="/about">
              <button class="mt-5 btn btn-yellow btn-center">
                <span class="btn-yellow-inner">About</span>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="isVisible">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <img class="modal-logo" src="@/assets/Group 1.png" />
              <h2 class="text-center text-uppercase">Information clause</h2>
              <div>
                <p>
                  According to Art. 13 of Regulation (EU) 2016/679 of the
                  European Parliament and of the Council of 27 April 2016 on the
                  protection of individuals with regard to the processing of
                  personal data and on the free movement of such data, and
                  repealing Directive 95/46/EC (General Data Protection
                  Regulation ) (Journal of Laws UE.L.2016.119.1 of 2016.05.04;
                  hereinafter: "GDPR"), I inform that:
                </p>
                <ul>
                  <li>
                    the administrator of your personal data is Piotr Martin, ul.
                    Kapelanka 6a/162, 30-347 Krakow;
                  </li>
                  <li>
                    Your personal data will be processed in order to perform the
                    contract - pursuant to art. 6 sec. 1 lit. b) GDPR, in order
                    to perform the obligation imposed by law on the
                    administrator in the form of keeping documentation and
                    records of payments - pursuant to art. 6 sec. 1 lit. c)
                    GDPR, for statistical purposes, personalization, web
                    analytics and adapting the www.cashflowstatement.online
                    website to the needs of users - pursuant to art. 6 sec. 1
                    lit. a) GDPR, as well as for analytics and business planning
                    pursuant to art. 6 sec. 1 lit. f) GDPR (legitimate interest
                    of the administrator);
                  </li>
                  <li>
                    the recipients of your personal data will be only entities
                    authorized to obtain personal data and entities
                    participating in the implementation of the service,
                    including entities providing IT services and an accounting
                    office serving the administrator;
                  </li>
                  <li>
                    you have the right to request from the administrator access
                    to personal data, rectification, deletion or limitation of
                    processing. You have the right to object to the processing
                    of your personal data, as well as the right to transfer
                    personal data. In order to exercise these rights, an
                    appropriate request should be sent to the administrator's
                    e-mail address: office@cashflowstatement.online;<br />
                    • in the event of violations of the protection of your
                    personal data, you have the right to lodge a complaint with
                    the supervisory authority;
                  </li>
                  <li>
                    in relation to personal data collected on the basis of
                    consent, you have the right to withdraw consent at any time.
                    This withdrawal does not affect the legality of the
                    processing of data covered by consent before its withdrawal;
                  </li>
                  <li>
                    providing personal data is voluntary, however, refusal to
                    provide data may result in refusal to conclude the contract
                    and perform the service. The collection of personal data is
                    justified by the concluded contract.
                  </li>
                </ul>

                <br />
                <h4 class="text-center text-uppercase">
                  consent to the use of cookies
                </h4>
                <br />
                <p>
                  Acting on the basis of art. 6 sec. 1 lit. a) Regulation of the
                  European Parliament and of the Council (EU) on the protection
                  of individuals with regard to the processing of personal data
                  and on the free movement of such data, and repealing Directive
                  95/46 / EC (General Data Protection Regulation; hereinafter:
                  'GDPR') I agree for processing by the administrator - Piotr
                  Martin with its registered office in Kraków, ul. Kapelanka
                  6a/152, 30-347 Kraków, my personal data in the form of the
                  history of the websites I visit, my activity on the Internet
                  by using cookies on the website www.cashflowstatement.online.
                  This consent includes the processing of this data for
                  statistical, personalization, web analytics purposes, as well
                  as adapting the website to the needs of users. This consent is
                  expressed in a conscious and voluntary manner.
                </p>
                <br />
                <p>
                  I declare that I am aware of the information clause provided
                  to me by the administrator in accordance with art. 13 GDPR. In
                  particular, I have been informed about the possibility of
                  withdrawing my consent at any time. Withdrawal may take place
                  in the form in which consent was given. I am aware that
                  failure to express or withdraw consent will not result in any
                  negative consequences. I certify that I am over 16 years old.
                  I declare that I am aware that if I wish to consent to the use
                  of cookies, except for the purposes indicated above, I can do
                  so by clicking on the link1 and selecting the appropriate
                  settings. Further information on the possibility of making
                  changes is available at this link.
                </p>
              </div>
              <button class="btn btn-yellow" @click="setConsent()">
                <span class="btn-yellow-inner">ACCEPT</span>
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// import UserService from '../services/user.service';

export default {
  name: "Home",
  created() {
    this.mapToData();
  },
  computed: {
    cookies() {
      return this.$store.getters.getCookieConsent;
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  methods: {
    mapToData() {
      if (localStorage.getItem("cookiesAccepted") === "yes") {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
    },
    setConsent() {
      localStorage.setItem("cookiesAccepted", "yes");
      this.$store.dispatch("setCookies", true);
      this.isVisible = false;
    },
    blurStyle() {
      if (this.isVisible) {
        return "filter: blur(8px);";
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.text-container {
  padding: 40px 70px;
}
.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
}
.title {
  font-weight: bold;
  font-style: italic;
}
.text-container {
  margin: 0 auto;
}

.img-col {
  position: fixed;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 50%;
  height: 100vh;
  z-index: -1;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;
}
.sectionBottom {
  margin-bottom: 40px;
}

.text-center {
  text-align: center;
}
.sectionTop {
  margin-top: 50px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80vw;
  margin: 0px auto;
  padding: 15px 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  word-break: inherit;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.modal-container p {
  padding: 0 20px;
}
.modal-container h2 {
  margin: 20px 0;
}
.modal-container ul {
  padding: 0px 20px 0px 40px;
}
.modal-container ul li {
  margin-bottom: 12px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.btn-center {
  align-self: center;
  justify-self: center;
}

.modal-body {
  margin: 20px 0;
}
.modal-logo {
  width: 150px;
  margin-bottom: 10px;
  object-fit: contain;
}

.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
.text-col {
  min-height: Calc(100vh - 47px);
  padding: 30px 120px;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 992px) {
  .img-col {
    width: 100%;
    opacity: 0.4;
  }
}
@media (max-width: 767px) {
  div,
  h6 {
    font-size: 1rem;
  } /*1rem = 16px*/
  h1 {
    font-size: 1.4rem;
  }
  .sectionBottom {
    margin-bottom: 10px;
  }
  .sectionTop {
    margin-top: 20px;
  }
  .text-col {
    min-height: Calc(100vh - 47px);
    padding: 30px 40px;
  }
}
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  div,
  h6 {
    font-size: 1.4rem;
  } /*1rem = 16px*/
  h1 {
    font-size: 1.6rem;
  }
  .sectionBottom {
    margin-bottom: 10px;
  }
  .sectionTop {
    margin-top: 20px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  div,
  h6 {
    font-size: 1rem;
  } /*1rem = 16px*/
  h1 {
    font-size: 1.4rem;
  }
  .sectionBottom {
    margin-bottom: 20px;
  }
  .sectionTop {
    margin-top: 30px;
  }
  .modal-container {
    width: 70vw;
    padding: 20px 50px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  div,
  h6 {
    font-size: 1.1rem;
  }
  h1 {
    font-size: 1.6rem;
  }
  .sectionBottom {
    margin-bottom: 30px;
  }
  .sectionTop {
    margin-top: 40px;
  }
}
@media (min-width: 1400px) {
  div,
  h6 {
    font-size: 1.1rem;
  } /*1rem = 16px*/
  h1 {
    font-size: 2.4rem;
  }
}
</style>
