<template>
  <div class="row no-gutters shadow">
    <div class="col-lg-6 img-container">
      <img src="../../src/assets/aa-1.png" class="img-fluid" />
    </div>

    <div class="col-lg-6 secondCol">
      <div class="sbuttons">
        <div @click="changeForm('signin')">
          <span :class="signinClass">Log in</span>
        </div>
        <div @click="changeForm('signup')">
          <span :class="signupClass">Register</span>
        </div>
      </div>
      <div v-if="signinForm">
        <form name="from" class="form shadow box" @submit.prevent="handleLogin">
          <p class="form-title">Log in and create a cash flow statement</p>
          <label for="name">Your Email</label>
          <div class="input-group mb-3">
            <input
              id="email"
              v-model="user.email"
              v-validate="'required'"
              type="text"
              class="form-control"
              name="email"
            />
          </div>
          <div
            v-if="errors.has('email')"
            class="alert alert-danger"
            role="alert"
          >
            Email is required!
          </div>
          <label for="email">Your Password</label>
          <div class="input-group mb-3">
            <input
              type="password"
              v-model="user.password"
              v-validate="'required'"
              class="form-control"
              name="password"
              aria-describedby="basic-addon3"
            />
          </div>
          <div
            v-if="errors.has('password')"
            class="alert alert-danger"
            role="alert"
          >
            Password is required!
          </div>
          <div class="form-group">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{
                message.status == 401
                  ? "Wrong email or password!"
                  : message.message
              }}
            </div>
          </div>
          <div
            class="flex"
            style="display:flex; justify-content:space-between;"
          >
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="checkbox" />
              <label
                class="form-check-label"
                for="checkbox"
                style="font-size:0.8rem;"
                >Remember password</label
              >
            </div>
            <div class="form-check">
              <p
                @click="changeForm('remind')"
                class="form-check-label"
                style="font-size:0.8rem; font-weight:500; text-decoration:underline; cursor:pointer;"
              >
                Remind password
              </p>
            </div>
          </div>

          <div class="text-right">
            <button :disabled="loading" class="mt-4 btn btn-yellow ">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span
                v-show="!loading"
                class="btn-yellow-inner"
                style="font-weight:bold;"
                >LOG IN</span
              >
            </button>
          </div>
        </form>
      </div>
      <div v-else-if="remindForm" class="">
        <form
          v-if="!successRemind"
          name="from"
          class="form shadow box"
          @submit.prevent="handleLogin"
        >
          <p class="form-title">Remind password</p>
          <p style="font-size:13px;">
            Enter your email address to which we will send a reminder message.
          </p>
          <label for="name">Your email.</label>
          <div class="input-group mb-3">
            <input
              id="email"
              v-model="user.email"
              v-validate="'required'"
              type="text"
              class="form-control"
              name="email"
            />
          </div>
          <div
            v-if="errors.has('email')"
            class="alert alert-danger"
            role="alert"
          >
            Email is required!
          </div>

          <div class="text-right">
            <button :disabled="loading" class="mt-4 btn btn-yellow ">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span
                v-show="!loading"
                class="btn-yellow-inner"
                style="font-weight:bold;"
                >REMIND</span
              >
            </button>
          </div>
        </form>

        <div class="" style="display:grid;place-items:center" v-else>
          <p style="font-size:30px;" class="form-title strike-32">Success</p>
          <p style="text-align:center;">
            A reminder message has been sent to your email address
          </p>
          <div class="text-right">
            <button @click="returnToInitialView" class="mt-4 btn btn-yellow ">
              <span class="btn-yellow-inner" style="font-weight:bold;"
                >LOG IN</span
              >
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <register></register>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import Register from "./Register";
import User from "../models/user";
import PaymentService from "../services/payment.service";
export default {
  name: "Login",
  components: {
    Register,
  },
  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: "",
      signinDisabled: true,
      signupDisabled: false,
      signinClass: "strike-32 last-char",
      signupClass: "disabled last-char",
      signinForm: this.getSigninFormStatus(),
      remindForm: false,
      successRemind: false,
    };
  },
  created() {
    if (this.signinForm) {
      this.changeForm("signin");
    } else {
      this.changeForm("signup");
    }
  },
  methods: {
    getSigninFormStatus() {
      if (this.$route.query.signin === "0") {
        return false;
      } else {
        return true;
      }
    },
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        if (this.remindForm) {
          if (this.user.email) {
            this.$store.dispatch("auth/remindPassword", this.user).then(
              (response) => {
                this.loading = false;
                this.successRemind = true;
              },
              (error) => {
                this.loading = false;
                this.message =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
                setTimeout(() => (this.message = ""), 2000);
              }
            );
          }
        } else {
          if (this.user.email && this.user.password) {
            this.$store.dispatch("auth/login", this.user).then(
              (response) => {
                if (
                  response.paymentStatus &&
                  response.paymentStatus == "pending"
                )
                  this.$router.push("/payment");
                else if (response.paymentData)
                  PaymentService.initPayment(
                    response.paymentData,
                    this.user.email
                  );
                else this.$router.push("/sheet");
              },
              (error) => {
                this.loading = false;
                this.message =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
                setTimeout(() => (this.message = ""), 2000);
              }
            );
          }
        }
      });
    },
    handleSendEmail() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        UserService.getSendEmailEbook(this.email).then(
          (response) => {
            this.message = response.status;
            setTimeout(() => (this.message = ""), 2000);
          },
          (error) => {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            setTimeout(() => (this.message = ""), 2000);
          }
        );
        this.loading = false;
      });
    },
    changeForm(name) {
      if (name === "signin") {
        this.signinClass = "strike-32 last-char";
        this.signupClass = "disabled last-char";
        this.signinForm = true;
      } else if (name === "signup") {
        this.signinClass = "disabled last-char";
        this.signupClass = "strike-32 last-char";
        this.signinForm = false;
      } else if (name === "remind") {
        this.signinForm = false;
        this.remindForm = true;
      }
    },
    returnToInitialView() {
      this.signinForm = true;
      this.remindForm = false;
      this.successRemind = false;
    },
  },
};
</script>

<style scoped>
@import "../assets/css/main.css";
@import "./LoginRegister.module.css";

.container-fluid {
  padding: 0;
}
.sbuttons {
  font-size: 32px;
  text-align: center;
  margin: 60px 0;
}
.sbuttons div {
  margin-right: 9px;
  display: inline-block;
  cursor: pointer;
}
img {
  position: fixed;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 50%;
  height: 100vh;
  z-index: -1;
}
@media only screen and (max-width: 520px) {
  .box {
    width: 100%;
  }
}
</style>
