import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "/auth/"; //"https://przeplywy.pl/api/auth/";//"http://77.55.215.133:8181/api/auth/";

class AuthService {
  login(user) {
    return axios
      .post(API_URL + "signin", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  remindPassword(user) {
    return axios.post(API_URL + "remindPassword", {
      email: user.email,
    });
  }

  setUserLanguage(user) {
    return axios
      .post(
        API_URL + "userLanguage/" + user.language,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(user));
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(user) {
    return axios.post(API_URL + "signup", {
      email: user.email,
      password: user.password,
      username: user.username,
      surname: user.surname,
      companyName: user.companyName,
      nip: user.nip,
      orderDate: user.orderDate,
      paymentDate: user.paymentDate,
      netAmount: user.netAmount,
      grossAmount: user.grossAmount,
      address: {
        street: user.street,
        city: user.city,
        postalCode: user.postalCode,
      },
    });
  }
}

export default new AuthService();
