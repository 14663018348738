<template>
  <div class="row no-gutters shadow">
    <div class="col-lg-6 img-container">
      <img src="../../src/assets/aa-1.png" class="img-fluid" />
    </div>

    <div class="col-lg-6 secondCol">
      <div class="text-container">
        <h5>
          <span class="strike-32">Account</span>
          <span class="strike-32">created</span>
          <span class="strike-32">successfuly!</span>
        </h5>
        <router-link to="/login">
          <button class="mt-4 btn btn-black ">
            <span class="btn-black-inner" style="font-weight:bold;"
              >Log in</span
            >
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessRegister"
};
</script>

<style scoped>
@import "../assets/css/main.css";
.text-container {
  text-align: center;
  margin: 50px auto;
  width: 300px;
}
img {
  position: fixed;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 50%;
  height: 100vh;
  z-index: -1;
}
</style>
